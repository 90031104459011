import React, { useState } from 'react'
import {
  ButtonNavbar,
  ContainerNavbar,
  ListItemNavbar,
  ListNavbar,
  MiddleContainerNavbar,
  LogoNavbar,
  ContainerReactLab,
  LogoReact,
  MobileMenu,
  Backdrop,
  BurgerMenu,
} from './NavbarComponents'
import logo from './react-1-logo-black-and-white.png'
import LandingScreen from '../LandingScreen/LandingScreen'
import Recruitment from '../Recruitment/Recruitment'
import Team from '../Team/Team'
import OurServices from '../OurServices/OurServices'

function Navbar({ setComponent }: { setComponent: any }) {
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = (componentToMount: React.ReactNode) => {
    setComponent(componentToMount)
    setIsOpen(false)
    console.log('ca click')
  }

  return (
    <ContainerNavbar>
      <ContainerReactLab
        onClick={() => setComponent(<LandingScreen />)}
      >
        <LogoReact src={logo} />
        <LogoNavbar>ReactLab</LogoNavbar>
      </ContainerReactLab>
      <MiddleContainerNavbar>
        <ListNavbar>
          <ListItemNavbar
            onClick={() => handleClick(<OurServices />)}
          >
            Nos Services
          </ListItemNavbar>
          <ListItemNavbar
            onClick={() => handleClick(<Recruitment />)}
          >
            On recrute
          </ListItemNavbar>
        </ListNavbar>
      </MiddleContainerNavbar>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://calendly.com/esteban-c-c/30min"
      >
        <ButtonNavbar>Contactez-nous</ButtonNavbar>
      </a>

      <Backdrop isOpen={isOpen} onClick={() => setIsOpen(false)} />

      {/* Burger Icon */}
      <BurgerMenu onClick={() => setIsOpen(!isOpen)}>
        <div></div>
        <div></div>
        <div></div>
      </BurgerMenu>

      {/* Mobile Menu */}
      <MobileMenu isOpen={isOpen}>
        <ListItemNavbar onClick={() => handleClick(<OurServices />)}>
          Nos Services
        </ListItemNavbar>
        <ListItemNavbar onClick={() => handleClick(<Team />)}>
          Notre équipe
        </ListItemNavbar>
        <ListItemNavbar onClick={() => handleClick(<Recruitment />)}>
          On recrute
        </ListItemNavbar>
        <ButtonNavbar>Contactez-nous</ButtonNavbar>
      </MobileMenu>
    </ContainerNavbar>
  )
}

export default Navbar
