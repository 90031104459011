import React from 'react'
import {
	PageContainer,
	OurServiceTextContainer,
	PageTitle,
	OurServicesContainer,
	OurServiceTitle,
	Separator,
	OurServiceText,
} from './OurServicesComponents'

function OurServices() {
	return (
		<PageContainer>
			<PageTitle>Nos Services</PageTitle>
			<OurServicesContainer>
				<OurServiceTextContainer>
					<OurServiceTitle>Resolution de bugs</OurServiceTitle>
					<Separator />
					<OurServiceText>
						Vous avez un bug sur votre site ou votre application que
						vos developpeur n'arrivent pas à résoudre ? Nous sommes là
						pour vous aider.
					</OurServiceText>
				</OurServiceTextContainer>
				<OurServiceTextContainer>
					<OurServiceTitle>Développement</OurServiceTitle>
					<Separator />
					<OurServiceText>
						Nous developpons votre projet de 0, avec les dernières
						technologies, et en respectant les bonnes pratiques. Nous
						pouvons aussi reprendre un projet existant.
					</OurServiceText>
				</OurServiceTextContainer>
				<OurServiceTextContainer>
					<OurServiceTitle>Analyse</OurServiceTitle>
					<Separator />
					<OurServiceText>
						Nous pouvons analyser votre projet, vos processus ou
						vos développeurs et vous apporter des solutions pour
						améliorer votre productivité.
					</OurServiceText>
				</OurServiceTextContainer>
			</OurServicesContainer>
		</PageContainer>
	)
}

export default OurServices
