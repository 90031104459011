import styled, { keyframes } from 'styled-components'

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const RecruitmentContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
  animation: ${fadeInAnimation} 1s ease-in-out;
`

export const RecruitmentTextContainer = styled.div`
	height: 100%;
	width: 100%;
  display: flex;
  flex-direction: column;
	justify-content: center;
  align-items: center;
  color: white;
`

export const RecruitmentTitle = styled.h1`
	font-size: 2rem;
  font-weight: bold;
  color: white;
  padding: 1rem;
	@media (max-width: 768px) {
		height: 10%;
	}

	@media (max-width: 600px) {
		font-size: 1.4rem;
	}
	
	@media (max-width: 400px) {
		font-size: 1.2rem;
	}
`

export const ChecksContainer = styled.div`
	display: flex;
	flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
	@media (max-width: 1024px) {
		height: 70%;
	}

	@media (max-width: 600px) {
		height: 80%;
	}
`

export const Button = styled.button`
  width: 200px;
  height: 40px;
  margin: 10px;
  padding: 10px;
  margin-top: 20px;
  border-radius: 5px;
  background-color: black;
  border: 2px solid white;
  color: white;
  &:disabled {
    background-color: black;
    border: 2px solid black;
    color: grey;
    &:hover {
      cursor: not-allowed;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: white;
    color: black;
  }

`

export const StyledLink = styled.a`
	height: 20%;
	@media (max-width: 600px) {
		height: 10%;
	}
	`

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  width: 25rem;
  height: 3rem;
  &:hover {
    font-weight: 900;
  }

	@media (max-width: 600px) {
		width: 15rem;
		height: 5rem;
		font-size: 0.8rem;
	}

`

export const CheckBoxLabel = styled.label`
  margin-left: 8px;
  color: white;
`

export const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  width: 20px;
  height: 20px;
  color: white;
  flex-shrink: 0;
  accent-color: grey;
`
