import styled from 'styled-components';

export const MemberContainer = styled.div`
		width: 300px;
		height: 200px;
		background-color: black;
		border-radius: 5px;
		border: 2px solid grey;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		@media (max-width: 1450px) {
			width: 350px;
		}

		@media (max-width: 450px) {
			height: 170px;
			width: 275px;
		}
`;

export const MemberDescription = styled.div`
		display: flex;
		flex-direction: column;
		height: 80%;
	
		justify-content: space-between;
		align-items: center;
		flex: 1;
		margin: 7px;
		padding: 7px;
`;

export const MemberNameAndRole = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: left;
`;


export const MemberName = styled.h1`
		font-size: 1.5rem;
		font-weight: bold;
		color: white;

		@media (max-width: 450px) {
			font-size: 1.2rem;
		}
`;

export const MemberRole = styled.h2`
		font-size: 1rem;
		font-weight: bold;
		color: white;

		@media (max-width: 450px) {
			font-size: 0.8rem;
		}
`;

export const PhotoAndBioContainer = styled.div`
    position: relative;
    width: 200px;
    height: inherit;
`;

export const MemberPhoto = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 5px;
    transition: filter 0.3s ease;

    ${PhotoAndBioContainer}:hover & {
        filter: blur(5px);
    }
`;

export const MemberBio = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 5px;

    ${PhotoAndBioContainer}:hover & {
        opacity: 1;
    }
`;

export const MemberLinkedin = styled.p`
font-size: 1rem;
font-weight: bold;
color: grey;
		&:hover {
	cursor: pointer;
	color: black;
}

@media (max-width: 450px) {
	font-size: 0.8rem;
}

`;
