import React, { useState, useEffect } from 'react'
import styled, { css, keyframes } from 'styled-components'
import Home from '../Home/Home'

const fade = keyframes`
	0% { opacity: 1;}
	100% { opacity: 0;  }
`

export const LoadingContainer = styled.div<{ $dismount: string }>`
  background-color: black;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${(props) =>
    props.$dismount
      ? css`
          ${fade} 0.5s ease-in-out forwards
        `
      : ''};
`

const TextContainer = styled.div`
  width: 500px;
  font-size: 7rem;
	display: flex;
  justify-content: center;
  color: white;
  animation: ${fade} 1s linear;

	@media (max-width: 1024px) {
		font-size: 5rem;
	}

	@media (max-width: 768px) {
		font-size: 3rem;
	}

	@media (max-width: 400px) {
		font-size: 2rem;
	}
`

function Loading({
  setComponent,
}: {
  setComponent: (value: JSX.Element) => void
}) {
  const [dismount, setDismount] = useState('')
  useEffect(() => {
    const timeout = setTimeout(() => {
      setComponent(<Home />)
    }, 1500) // Set to true after 2.5s
    const closeToDismount = setTimeout(() => {
      setDismount('true') // Set to true when close to dismounting
    }, 1000) // Set to true after 2.5s

    return () => {
      clearTimeout(timeout)
      clearTimeout(closeToDismount)
    }
  }, [setComponent])

  return (
    <LoadingContainer $dismount={dismount}>
      <TextContainer>ReactLab</TextContainer>
    </LoadingContainer>
  )
}

export default Loading
