import React, { useEffect, useState } from 'react'
import {
  RectangleContainer,
  HomeContainer,
  ContentContainer,
  ButtonClose,
} from './HomeComponents'
import Navbar from '../../components/Navbar/Navbar'

import Footer from '../../components/Footer/Footer'
import LandingScreen from '../../components/LandingScreen/LandingScreen'

function Home() {
  const [mounted, setMounted] = useState('')
  const [component, setComponent] = useState(<LandingScreen />)

  const [showCloseButton, setShowCloseButton] = useState(false)

  useEffect(() => {
    setShowCloseButton(component.type.name !== 'LandingScreen')
    setMounted('true')
  }, [component])

  return (
    <HomeContainer $mounted={mounted}>
      <Navbar setComponent={setComponent} />
      <ContentContainer>
        <RectangleContainer>
          {showCloseButton && (
            <ButtonClose
              onClick={() => setComponent(<LandingScreen />)}
            >
              X
            </ButtonClose>
          )}
          {component}
        </RectangleContainer>
      </ContentContainer>
      <Footer component={component} setComponent={setComponent} />
    </HomeContainer>
  )
}

export default Home
