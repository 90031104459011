import styled from 'styled-components'

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`

export const PageTitle = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: white;
  padding: 1rem;
`

export const OurServicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  // @media screen and (orientation: portrait) {
  //   flex-direction: column;
  // }
	@media (max-width: 1024px) {
    flex-direction: column;
  }

  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
`

export const OurServiceTextContainer = styled.div`
  height: fit-content;
  width: 50vh;
  border: 0.5px solid grey;
  display: flex;
  @media screen and (orientation: landscape) {
    height: 40vh;
    width: 25vw;
  }
  flex-direction: column;
  align-items: center;
  border-radius: 5px;

	@media (max-width: 1024px) {
		width: 90%;
		margin: 1.5rem;
		height: 30vh;
	}

	@media (max-width: 768px) {
		width: 90%;
		margin: 1.5rem;
	}

	&:hover {
		border: 0.5px solid white;
	}
`
export const OurServiceTitle = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  padding: 1rem;
	@media (max-width: 400px) {
		font-size: 1.3rem;
	}
`
export const Separator = styled.div`
  height: 1px;
  width: 80%;
  background-color: white;
`

export const OurServiceText = styled.p`
  margin-top: 2rem;
  font-size: 1rem;
  color: white;
  padding: 1rem;
	@media (max-width: 600px) {
		font-size: 0.8rem;
	}
`