import React from 'react'
import {
	RecruitmentContainer,
	RecruitmentTextContainer,
	Button,
	RecruitmentTitle,
	CheckBox,
	CheckBoxContainer,
	CheckBoxLabel,
	ChecksContainer,
	StyledLink,
} from './RecruitmentComponents'

function Recruitment() {
	return (
		<RecruitmentContainer>
			<RecruitmentTextContainer>
				<RecruitmentTitle>Rejoignez notre equipe</RecruitmentTitle>
				<ChecksContainer>
					<CheckBoxContainer>
						<CheckBox
							id="experience"
							name="Expérience"
							checked={false}
						/>
						<CheckBoxLabel htmlFor="experience">
							5 ans+ d'expérience en tant que développeur front-end,
							avec une solide maîtrise de React / React Native.
						</CheckBoxLabel>
					</CheckBoxContainer>
					<CheckBoxContainer>
						<CheckBox
							id="technologie"
							name="Technologie"
							checked={false}
						/>
						<CheckBoxLabel htmlFor="technologie">
							React, Redux, JavaScript/TypeScript, et les classiques.
						</CheckBoxLabel>
					</CheckBoxContainer>
					<CheckBoxContainer>
						<CheckBox
							id="technologie"
							name="Technologie"
							checked={false}
						/>
						<CheckBoxLabel htmlFor="technologie">
							Maitrise du TDD, CQRS, DDD et les principes SOLID.
						</CheckBoxLabel>
					</CheckBoxContainer>
					<CheckBoxContainer>
						<CheckBox
							id="technologie"
							name="Technologie"
							checked={false}
						/>
						<CheckBoxLabel htmlFor="technologie">
							Maitrise de la clean architecture et des design patterns.
						</CheckBoxLabel>
					</CheckBoxContainer>
					<CheckBoxContainer>
						<CheckBox
							id="initiative-client"
							name="Initiative-Client"
							checked={false}
						/>
						<CheckBoxLabel htmlFor="initiative-client">
							Capacité à prendre des initiatives, gérer de manière
							autonome les relations et les communications avec les
							clients, et répondre de manière proactive et efficace à
							leurs besoins.
						</CheckBoxLabel>
					</CheckBoxContainer>
				</ChecksContainer>
				<StyledLink
					href={'https://calendly.com/esteban-c-c/30min'}
					target="_blank"
					rel="noopener noreferrer"
				>
					<Button>Oui !</Button>
				</StyledLink>
			</RecruitmentTextContainer>
		</RecruitmentContainer>
	)
}

export default Recruitment
