import React, { useEffect, useState } from 'react'
import {
	CustomerReviewContainer,
	CustomerReviewsContainer,
	CustomerReviewsParagraph,
	FooterContainer,
	OpenLogoContainer,
} from './FotterComponents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

const reviews = [
	{
		websiteUrl: 'https://gxpmanager.com/',
		name: 'GxpManager',
		logoUrl:
			'https://gxpmanager.com/wp-content/uploads/2022/04/logo-gxpmanager-2022.svg',
		review: `Notre expérience avec ReactLab a été exceptionnelle. Après un an de développement d'une application, un bug majeur nous bloquait. En une semaine, ReactLab l'a identifié et en deux semaines, l'a résolu. Leur efficacité et expertise sont remarquables. Nous recommandons leur service pour des solutions rapides et fiables en développement web.`,
	},
	{
		websiteUrl: 'https://www.leguideultimedeparis.fr/',
		name: 'Le guide Ultime',
		logoUrl:
			'https://static.wixstatic.com/media/004330_155aa5e170d5426eb1206c56c5e972f0~mv2.png/v1/fill/w_73,h_83,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/stickers_guideparis.png',
		review: `ReactLab a rapidement compris l'enjeu de notre mission. C'est un réel plaisir de travailler avec eux :
	ils ont résolu un bug en quelques jours, qui existait depuis des mois.
	Ils sont pédagogues et ont su nous rendre autonomes suite à leur déploiement. Je recommande vivement,
	ils sont créatifs et inventifs, et aucune situation ne leur résiste :) Merci ReactLab !!`,
	},
	{
		logoUrl:
			'https://media.licdn.com/dms/image/C4D0BAQGU1G7f39KWwQ/company-logo_200_200/0/1541076369344?e=1705536000&v=beta&t=bUvV3-WI5YFWKbetp33iQ8soFNRH3VW9eN4pdBaS0Io',
		name: 'DESP IT',
		review: `L'expertise du développeur fourni par ReactLab a été exceptionnelle, démontrant un haut niveau d'engagement et une grande capacité à apporter des idées novatrices. Je ne peux que recommander leurs services.`,
	},
	{
		websiteUrl: 'https://www.circular.xyz/',
		name: 'Circular',
		logoUrl:
			'https://uploads-ssl.webflow.com/5c9cf810e0682292a9da04b8/5fa2fe1db0b5b842dbcafad8_Circular%20logo%20without%20Be%20more-p-500.png',
		review: `Nous sommes ravis de travailler avec ReactLab. Leur motivation, engagement, et expertise sont remarquables, même lors des pics d'activité. Nous recommandons vivement ReactLab à toute entreprise cherchant un partenaire de développement web fiable.`,
	},
]

function Footer({
	component,
	setComponent,
}: {
	component: any
	setComponent: any
}) {
	const [currentReviewIndex, setCurrentReviewIndex] = useState(0)
	const [isParagraphHovered, setIsParagraphHovered] = useState(false);

	useEffect(() => {
		if (!isParagraphHovered) {
			const timer = setTimeout(() => {
				setCurrentReviewIndex(
					(prevIndex) => (prevIndex + 1) % reviews.length
				)
			}, 5000)

			return () => clearTimeout(timer)
		}
	}, [currentReviewIndex, isParagraphHovered])

	const currentReview = reviews[currentReviewIndex]

	// const handleClick = (componentToMount: any) => {
	// 	if (
	// 		component.type.name !== 'LandingScreen' &&
	// 		component.type.name === componentToMount.type.name
	// 	) {
	// 		setComponent(<LandingScreen />)
	// 	} else setComponent(componentToMount)
	// }

	return (
		<FooterContainer>
			{/* <CustomerReviewsContainer
				onClick={() => handleClick(<Review />)}
			> */}
			<CustomerReviewsContainer
				onMouseEnter={() => setIsParagraphHovered(true)}
				onMouseLeave={() => setIsParagraphHovered(false)}>
				<ClientLogo src={currentReview.logoUrl}></ClientLogo>
				<CustomerReviewContainer >
					<CustomerReviewsParagraph>{currentReview.review}</CustomerReviewsParagraph>
				</CustomerReviewContainer>
				<OpenLogoContainer>
					<FontAwesomeIcon
						icon={faUpRightAndDownLeftFromCenter}
						style={{
							color: '#000000',
						}}
					/>
				</OpenLogoContainer>
			</CustomerReviewsContainer>
		</FooterContainer>
	)
}

export default Footer

const ClientLogo = styled.img`
  width: 8vw;
  height: 8vw;
  object-fit: contain;
  transition: all 1s ease;
  &:hover {
    transition: all 1s ease;
    transform: scale(1.1);
  }
  margin-right: 3vw;
`
