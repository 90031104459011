import styled from 'styled-components'
import { slideInLeftAnimation } from '../../style/Animation/AnimationComponents'

export const TextContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
`

export const TitleReactLab = styled.h1`
  font-size: 4rem;
  margin: 1rem 3rem;
  animation: ${slideInLeftAnimation} 1s ease-in-out;
`

export const DescriptionReacLab = styled.p`
  font-size: 2rem;
  margin: 1rem 3rem;
  animation: ${slideInLeftAnimation} 1s ease-in-out;
`

export const GifContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Center = styled.div`
  margin: 2rem;
  display: flex;
  justify-content: center;
`

export const PageContainer = styled.div`
	height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const CenterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex: 1;
	@media (max-width: 768px) {
		flex-direction: column;
	}
`