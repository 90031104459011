import styled from 'styled-components'

export const ContainerNavbar = styled.div`
  height: 8vh;
  min-height: 100px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90vw;
  margin: 0vw 5vw;
`

export const LogoReact = styled.img`
  width: 50px;
  height: 50px;
  margin: 1rem;
  border-radius: 10px;
  @media (max-width: 768px) {
    margin: 0px;
  }
`

export const ContainerReactLab = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const LogoNavbar = styled.h1`
  font-weight: bold;
  font-size: 2rem;
  color: black;

  @media (max-width: 870px) {
    display: none;
  }
	@media (max-width: 600px) {
    display: block;
		margin-left: 1rem;
  }
`

export const ButtonBase = styled.button`
  font-size: 1.2rem;
  width: 12rem;
  height: 3rem;

  margin-right: 1rem;
  background-color: white;
  border: 1px solid white;
  color: black;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    background-color: black;
    color: white;
  }
`

export const ButtonNavbar = styled.button`
  font-size: 1.5rem;
  width: 11.5rem;
  height: 3rem;

  background-color: black;
  border: 1px solid black;
  color: white;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    background-color: white;
    color: black;
  }

	@media (max-width: 1024px) {
		font-size: 1.2rem;
		width: 10rem;
		height: 3rem;
	}

	@media (max-width: 768px) {
		font-size: 1rem;
		width: 10rem;
		height: 3rem;
	}

	@media (max-width: 600px) {
		display: none;
	}
`

export const MiddleContainerNavbar = styled.div``

export const ListNavbar = styled.ul`
  display: flex;
  list-style: none;
	@media (max-width: 600px) {
    display: none;
  }
`

export const ListItemNavbar = styled.li`
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0 2rem;
	// border: 1px solid black;

  @media (max-width: 768px) {
    margin: 0 0.2rem;
  }

  transition: transform 0.2s;
  &:hover {
    cursor: pointer;
    font-weight: bold;
    transform: scale(1.2);
  }

	@media (max-width: 1024px) {
		font-size: 1rem;
		margin: 0 1rem;
	}

	@media (max-width: 600px) {
		width: 8rem;
		padding: 0.5rem;
`


export const BurgerMenu = styled.div`
  width: 30px;
  height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  z-index: 1001;  // Ensure the burger is above everything else

  @media (min-width: 600px) {
    display: none;
  }

  div {
    width: 30px;
    height: 4px;
    background-color: black;
    transition: all 0.3s ease;
  }
`;



export const MobileMenu = styled.div <{ isOpen: boolean }>`
	z-index: 1000;
	position: fixed;
  top: 0;
  right: 0;
  width: 70%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.9); // Slight transparency
  backdrop-filter: blur(4px); // Blur effect
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};

  @media (min-width: 600px) {
    display: none;
  }
`;

export const Backdrop = styled.div <{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3); // Semi-transparent black
  z-index: 999; // Ensure the backdrop is behind the menu but above other content
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;