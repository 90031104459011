import React, { useState } from 'react'
import styled from 'styled-components'
import Loading from './pages/Loading/Loading'

const AppContainer = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
`

function App() {
  const [component, setComponent] = useState<React.ReactNode>(null)

  return (
    <AppContainer>
      {component ? (
        component
      ) : (
        <Loading setComponent={setComponent} />
      )}
    </AppContainer>
  )
}

export default App
