import React from 'react'
import { MemberSquare, PageContainer, PageTitle, TeamContainer } from './TeamComponents'
import Member from './Member/Member'

const team = [
  {
    name: 'Alice D.',
    role: 'Lead développeur Front-End',
    photo: 'https://thispersondoesnotexist.com/',
    linkedin: 'https://www.linkedin.com',
    bio: 'Non',
  },
  {
    name: 'Bob M.',
    role: 'Développeur Front-End',
    photo: 'https://thispersondoesnotexist.com',
    linkedin: 'https://www.linkedin.com',
    bio: '667',
  },
  {
    name: 'Charlie B.',
    role: 'Développeur Front-End',
    photo: 'https://thispersondoesnotexist.com',
    linkedin: 'https://www.linkedin.com',
    bio: 'Ekip',
  },
  {
    name: 'Daisy L.',
    role: 'Développeur Front-End',
    photo: 'https://thispersondoesnotexist.com',
    linkedin: 'https://www.linkedin.com',
    bio: 'On est la pour le taf',
  },
]

function Team() {
  return (
		<PageContainer>
			<PageTitle>Notre Équipe</PageTitle>
			<TeamContainer>
      {team.map((member, index) => (
        <MemberSquare key={index}>
          <Member member={member} />
        </MemberSquare>
			))}
			</TeamContainer>
    </PageContainer>
  )
}

export default Team
