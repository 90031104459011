import React from 'react'
import {
	Center,
	CenterContainer,
  DescriptionReacLab,
  GifContainer,
  PageContainer,
  TextContainer,
  TitleReactLab,
} from './LandingScreenComponents'

import gif from '../../assets/Gif.gif'
import { ButtonBase } from '../Navbar/NavbarComponents'

function LandingScreen() {
  return (
    <PageContainer>
      <CenterContainer>
        <TextContainer>
          <TitleReactLab>React Lab</TitleReactLab>
          <DescriptionReacLab>
            Le Collectif d'expert React / React Native
          </DescriptionReacLab>
        </TextContainer>
        <GifContainer>
          <img
            src={gif}
            alt="loading..."
            onDragStart={(event) => event.preventDefault()}
					/>
        </GifContainer>
      </CenterContainer>
      <Center>
        <ButtonBase>Nos Services</ButtonBase>
      </Center>
    </PageContainer>
  )
}

export default LandingScreen