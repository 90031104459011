import React from 'react'
import { MemberBio, MemberContainer, MemberDescription, MemberName, MemberNameAndRole, MemberPhoto, MemberRole, PhotoAndBioContainer } from './MemberComponents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

type MemberProps = {
	member: {
		name: string;
		role: string;
		photo: string;
		linkedin: string;
		bio: string;
	};
};

function Member({ member }: MemberProps) {
	return (
		<MemberContainer>
			<MemberDescription>
				<MemberNameAndRole>
					<MemberName>{member.name}</MemberName><br />
					<MemberRole>{member.role}</MemberRole>
				</MemberNameAndRole>
				<FontAwesomeIcon icon={faLinkedin} size="xl" style={{ color: "#fff", }} />
			</MemberDescription>
			<PhotoAndBioContainer>
				<MemberPhoto src={member.photo} alt={member.name} />
				<MemberBio>{member.bio}</MemberBio>
			</PhotoAndBioContainer>
		</MemberContainer>
	)
}

export default Member