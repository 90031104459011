import styled from 'styled-components'
import { slideInLeftAnimation } from '../../style/Animation/AnimationComponents'

export const HomeContainer = styled.div<{ $mounted: string }>`
  background-color: #ffffff;
  opacity: ${(props) => (props.$mounted ? 1 : 0)};
  transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
  display: flex;
  flex-direction: column;
  height: 100vh;
	overflow-x: hidden;

	@media (max-width: 1024px) {
		height: auto;
	}
`

export const ContentContainer = styled.div`
  flex: 1;
  margin-top: 1vh;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
`

export const RectangleContainer = styled.div`
  overflow-x:hidden;
	height: 70vh;
  width: 90vw;
  background-color: black;
  border-radius: 20px;
	// display: flex;
	// justify-content: center;

	@media (max-width: 1024px) {
		height: auto;
	}

	@media (max-width: 768px) {
		height: auto;
	}
`

export const ButtonClose = styled.button`
  position: relative;
	left: 95%;
	top: 5%;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: black;
  border: 2px solid white;
  color: white;
  &:hover {
    cursor: pointer;
    background-color: white;
    color: black;
  }

	@media (max-width: 1024px) {
		left: 90%;
	}

	@media (max-width: 768px) {
		left: 85%;
		top: 3%;
	}

  animation: ${slideInLeftAnimation} 1.75s ease-in-out;
`
