import styled from 'styled-components'

export const PageContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
	width: 100%;
	height: 100%;
`

export const PageTitle = styled.h1`
	font-size: 2rem;
	font-weight: bold;
	color: white;
	padding: 1rem;
`

export const TeamContainer = styled.div`
	display: flex;

	flex-wrap: wrap;
	justify-content: center;
  align-items: center;

	@media (max-width: 1400px) {
		flex-wrap: wrap;
	}

	@media (max-width: 1000px) {
		flex-direction: column;
	}
`;

export const MemberSquare = styled.div`
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: transform 0.3s;
  
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  }
`;