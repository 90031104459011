import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const FooterContainer = styled.div`
  min-height: 130px;
  margin: 2vh 5vw;
  // width: 90vw;
  background-color: #ffffff;
  border: 3px solid transparent;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s ease;

  &:hover {
    transition: all 1s ease;
    border-color: black;
  }

	@media (max-width: 1024px) {
		height: auto;
	}
`

export const CustomerReviewsContainer = styled.div`
  flex: 2;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  animation: ${fadeIn} 0.5s ease-in-out;
	height: 100px; /* adjust as needed */
	@media (max-width: 1024px) {
		height: auto;
	}

	// @media (max-width: 400px) {
	// 	overflow: auto;
	// }
`

export const CustomerReviewsParagraph = styled.p`
	margin: 0; 
	font-size: 1.2rem;
	@media (max-width: 1024px) {
		font-size: 1rem;
	}
`

export const CustomerNameContainer = styled.div`
  flex: 0.5;
  padding: 0 15px;
`

export const CustomerName = styled.h3`
  transition: all 1s ease;
  font-size: 2rem;
`

export const CustomerReviewContainer = styled.div`
  transition: all 1s ease;
  flex: 2;
  font-size: 1.5rem;
`

export const SocialMediaContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const LogoContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`

export const EmailContainer = styled.div`
  margin-right: 10px;
`

export const Separator = styled.div`
  height: 80%;
  border-left: 2px solid black;
  border-radius: 10px;
`

export const OpenLogoContainer = styled.div`
  opacity: 0;

  &:hover {
    opacity: 1;
    transition: 1s ease;
  }
`
